import React, { useEffect } from "react";
import styled from "styled-components";
import CookieBanner from "../components/CookieBanner";
import Footer from "../components/Footer";
import LayoutSection from "../components/layout/LayoutSection";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import SEO from "../components/seo";
import SiteHeader from "../components/SiteHeader";
import H3 from "../components/typography/H3";
import P2 from "../components/typography/P2";
import colors from "../components/style/colors";
import "../main.css";
import Image from "../components/image";
import Helmet from "react-helmet";

const Logo = styled.div`
  .gatsby-image-wrapper {
    display: block;
    width: 6em;
    height: 6em;
    margin: 0 auto 1em;
  }
`;

const DownloadPage = props => {
  useEffect(() => {
    window.location.href = "https://accounts.boxysuite.com/redirect/download_suite";
  }, []);

  return (
    <>
      <CookieBanner />
      <LayoutWrapper>
        <SEO title="" canonical={props.location.href} />
        <Helmet>
          <meta name="robots" content="noindex, follow" />
        </Helmet>

        <SiteHeader dark />
        <LayoutSection small style={{ paddingTop: "6em" }}>
          <Logo>
            <Image
              filename="icons/Suite"
              style={{ position: "relative" }}
              alt="Download Boxy Suite 2"
            />
          </Logo>
          <H3 center>Downloading Boxy Suite...</H3>
          <P2 color="grey" center>
            If the download hasn't started in a few seconds,{" "}
            <a href="https://accounts.boxysuite.com/redirect/download_suite">click here</a>.
          </P2>
        </LayoutSection>
        <Footer />
      </LayoutWrapper>
    </>
  );
};

export default DownloadPage;
